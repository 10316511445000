import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
}

import React from 'react';
import { GenericTemplate, usePrismicPreviewPageData } from '@walltowall/gatsby-theme-ww-prismic';
import { slicesMap } from 'src/slices/PageBody';
import { SpeedBump } from 'src/components/SpeedBump';

const slicesMiddleware = (list = []) => [{
  __typename: 'PageBodyHeader',
  id: 'header'
}, {
  __typename: 'PageBodySectionHeader',
  id: 'career_section_header',
  primary: {
    heading: {
      text: 'Careers'
    },
    color_scheme: 'Dark Teal'
  }
}, {
  __typename: 'PageBodyCareerMetadata',
  id: 'career_metadata'
}].concat(_toConsumableArray(list), [{
  __typename: 'PageBodyCareerApplyButton',
  id: 'career_apply_button'
}, {
  __typename: 'PageBodyFooter',
  id: 'footer'
}]);

export const CareerTemplate = _ref => {
  let {
    data: rawData,
    location
  } = _ref,
      props = _objectWithoutProperties(_ref, ["data", "location"]);

  const data = usePrismicPreviewPageData({
    data: rawData,
    location
  });
  return React.createElement(React.Fragment, null, React.createElement(GenericTemplate, Object.assign({
    customType: "page",
    sliceZoneId: "body",
    slicesMap: slicesMap,
    slicesMiddleware: slicesMiddleware,
    data: data,
    location: location
  }, props)), React.createElement(SpeedBump, null));
};
export default CareerTemplate;
export const query = "4104139332";