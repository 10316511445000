import React from 'react';
import { UnpublishedPreviewTemplate } from '@walltowall/gatsby-theme-ww-prismic';
import CareerTemplate from 'src/templates/career';
import NewsTemplate from 'src/templates/news';
import PageTemplate from 'src/templates/page';
const templateMap = {
  career: () => CareerTemplate,
  news: () => NewsTemplate,
  page: () => PageTemplate
};
export const UnpublishedPreviewPage = props => React.createElement(UnpublishedPreviewTemplate, Object.assign({
  templateMap: templateMap
}, props));
export default UnpublishedPreviewPage;